// Import our custom CSS

import '../scss/theme.scss'

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

function navbarBehaviour() {

    var navbar = document.querySelector("header .navbar.js--hideScroll");
    // Trovo l'altezza della navbar

    if (navbar) {
        var navbarHeight = navbar.offsetHeight;

        // Aggiungo l'altezza della navbar come padding all'elemento main
        var mainContent = document.querySelector('main.main-content');
        mainContent.style.paddingTop = navbarHeight + 'px';

        // Salvo la posizione dello scroll verticale
        var prevScrollpos = window.pageYOffset;
        var scrolledDown = false;

        // Evento scroll sull'oggetto window
        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;

            // Se l'user scrolla +200px si attiva l'effetto
            if (currentScrollPos > 200) {
                scrolledDown = true;
            } else {
                scrolledDown = false;
            }

            if (scrolledDown) {
                if (prevScrollpos > currentScrollPos) {
                    navbar.style.top = '0';
                } else {
                    navbar.style.top = '-' + navbarHeight + 'px';
                }
            }

            prevScrollpos = currentScrollPos;
        };
    }

}

navbarBehaviour();


jQuery(function ($) {

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        //>=, not <=
        if (scroll >= 150) {
            //clearHeader, not clearheader - caps H
            $("body").addClass("is-scrolled");
        } else {
            $("body").removeClass("is-scrolled");
        }
    }); //missing );

    $.fn.isInViewport = function () {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + ($(window).height() / 2);
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $(window).on('resize scroll', function () {
        $('.timeline-main ul.timeline-list > li').each(function () {
            var activeColor = $(this).attr('id');
            if ($(this).isInViewport()) {
                $(this).addClass('adding');
            } else {
                $(this).removeClass('adding');
            }
        });
    });

    $(window).on('load', function () {
        if ($('.timeline-main').length > 0) {
            var winHeight = $(window).height(),
                element = $('.timeline-main'),
                elementTop = $(element).offset().top - winHeight,
                elementHeight = $(element).outerHeight();

            var scrollPos = $(window).scrollTop();

            // console.log('elementTop ', elementTop, ' elementHeight ', elementHeight, ' scrollPos ', scrollPos);

            $(window).on('scroll', function () {
                scrollPos = $(window).scrollTop() - $(window).height() / 2;
                var r = scrollPos - elementTop;
                // console.log('r ' + r);
                if (scrollPos >= elementTop) {
                    // console.log('inn');
                    if (elementHeight >= r) {
                        $('.pogress').css('height', r);
                    }
                }
                if (r < 0) {
                    // console.log('0 inn')
                    $('.pogress').css('height', 0);
                }

                if (elementHeight < r) {
                    $('.pogress').css('height', elementHeight);
                }

            });
        }
    });

    function equalHeight(group) {
        if ($(window).width() > 992) {
            var tallest = 0;
            group.each(function () {
                var thisHeight = $(this).height();
                if (thisHeight > tallest) {
                    tallest = thisHeight;
                }
            });
            group.height(tallest);
        }
    }

    equalHeight($(".focus-item h3"));
    equalHeight($(".focus-item p"));


}); // jQuery End

document.addEventListener("DOMContentLoaded", function () {
    ScrollTrigger.batch(".fade-up", {
        start: "top 80%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.3, y: 0, duration: 1.5});
        }

    });

    ScrollTrigger.batch(".fade-left", {
        start: "top 80%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.3, x: 0, duration: 1.5});
        }

    });

    if (document.getElementById('map')) {
        var mymap = L.map('map', {
            center: [43.911431, 11.0304149], // Posizione iniziale della mappa
            zoom: 16, // Livello di zoom iniziale (più alto)
            scrollWheelZoom: false, // Disabilita lo zoom tramite lo scroll
            zoomControl: false // Disabilita i controlli di zoom predefiniti
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 20, // Imposta il livello di zoom massimo
            // attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors' // Attribuzione dei dati della mappa
        }).addTo(mymap);

        // Aggiunge un marker con un'icona personalizzata
        var customIcon = L.divIcon({
            className: 'custom-icon',
            html: '<i class="fa fa-map-marker" aria-hidden="true"></i>', // Icona di Font Awesome
            iconSize: [30, 42], // Dimensioni dell'icona
            iconAnchor: [15, 42], // Punto di ancoraggio dell'icona (regola qui per centrare il puntatore)
            popupAnchor: [-7, -30] // Posizione del popup rispetto all'icona (regola qui per evitare sovrapposizione)
        });

        var marker = L.marker([43.911431, 11.0304149], {
            icon: customIcon // Icona personalizzata
        }).addTo(mymap).bindPopup("C.M.T. Srl").openPopup(); // Bind del popup

        // Aggiunge i controlli di zoom personalizzati in basso a destra
        L.control.zoom({position: 'bottomright'}).addTo(mymap);
    }

});